import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDevice } from 'hooks/useDevice';
import { useOutletContext } from 'react-router-dom';
import MessageButton from 'components/molecules/ToolsButtons/MessageButton';
import HomeImages from './HomeImages';
import { HomeContextInterface } from 'types/outlet_context_models';
import { DialogWindowType } from 'layouts/hooks/useFormsApp';
import { MARKET_ROUTE } from 'router/routes';
import { scrollPage } from 'utils/scrollPage';
import InformationButton from 'components/atoms/Buttons/InformationButton';
import { Colors } from 'constants/colors';
import mainLogo from 'assets/img/main_logo.webp';
import CocktailButton from 'components/atoms/Buttons/CocktailButton';
import Loader from 'components/atoms/Loader/Loader';

const HomePage = () => {
    const { appXPadding, footerMenuHeight, handleOpenDialog, string }: HomeContextInterface = useOutletContext();
    const { sx } = useDevice();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        scrollPage(0);
    }, []);

    setTimeout(() => {
        setIsLoading(false);
    }, 200);

    if (isLoading) return <Loader />;

    return (
        <Box p={sx ? 2 : appXPadding} sx={{ pb: `${footerMenuHeight}px`, position: 'relative' }}>
            <MessageButton action={() => handleOpenDialog(DialogWindowType?.QUESTION)} />
            <InformationButton />
            <HomeImages />

            {/* Первый блок с уникальной анимацией */}
            <Box
                className="InformationHomePage gradient-1"
                px={1.5}
                pr={sx ? 2 : 25}
                pl={2}
                py={1.25}
                sx={{
                    height: '88px !important',
                    position: 'fixed',
                    top: 88,
                    left: sx ? 12 : 32,
                    borderRadius: 4,
                    width: 'calc(100vw - 24px)',
                    maxWidth: sx ? '95%' : 750,
                    color: '#fff',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
                }}
            >
                <Typography
                    mb={1}
                    sx={{
                        color: Colors?.WHITE,
                        fontSize: sx ? 16 : 20,
                        fontWeight: 500,
                        fontFamily: 'Roboto',
                    }}
                >
                    • {string?.customer_description_1}
                </Typography>
            </Box>

            {/* Второй блок с уникальной анимацией */}
            <Box
                className="InformationHomePage gradient-2"
                px={1.5}
                pr={sx ? 2 : 25}
                pl={2}
                py={1.25}
                sx={{
                    height: '88px !important',
                    position: 'fixed',
                    top: 191,
                    left: sx ? 12 : 32,
                    borderRadius: 4,
                    width: 'calc(100vw - 24px)',
                    maxWidth: sx ? '95%' : 750,
                    color: '#fff',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
                }}
            >
                <Typography
                    mb={1}
                    sx={{
                        color: Colors?.WHITE,
                        fontSize: sx ? 16 : 20,
                        fontWeight: 500,
                        fontFamily: 'Roboto',
                    }}
                >
                    • {string?.customer_description_2}
                </Typography>
            </Box>

            {/* Третий блок с уникальной анимацией */}
            <Box
                className="InformationHomePage gradient-3"
                px={1.5}
                pr={sx ? 2 : 25}
                pl={2}
                py={1.25}
                sx={{
                    height: '88px !important',
                    position: 'fixed',
                    top: 293.5,
                    left: sx ? 12 : 32,
                    borderRadius: 4,
                    width: 'calc(100vw - 24px)',
                    maxWidth: sx ? '95%' : 750,
                    color: '#fff',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
                }}
            >
                <Typography
                    sx={{
                        color: Colors?.WHITE,
                        fontSize: sx ? 16 : 20,
                        fontWeight: 500,
                        fontFamily: 'Roboto',
                    }}
                >
                    • {string?.customer_description_3}
                </Typography>
            </Box>

            <Box
                sx={{
                    position: 'fixed',
                    top: sx ? '' : 160,
                    bottom: sx ? 150 : '',
                    left: sx ? '' : 600,
                    right: sx ? '5%' : '',
                }}
            >
                <CocktailButton path={MARKET_ROUTE?.root()} logoUrl={mainLogo} text={'SHOP NOW!'} />
            </Box>

            <style>
                {`
                .gradient-1 {
                    height: auto;
                    width: 100%;
                    background: linear-gradient(149deg, rgba(190, 190, 190, 0.75) 0%, rgba(134, 134, 134, 0.75) 42%, rgba(83, 83, 83, 0.75) 72%, rgba(51, 57, 92, 0.75) 100%);
                    animation: gradient-1 8s infinite linear;
                    background-size: 400%;
                }
    
                .gradient-2 {
                    height: auto;
                    width: 100%;
                    background: linear-gradient(149deg, rgba(150, 150, 150, 0.75) 0%, rgba(71, 71, 71, 0.75)  42%, rgba(51, 57, 92, 0.75) 72%, rgba(190, 190, 190, 0.75) 100%);
                    animation: gradient-2 10s infinite linear;
                    background-size: 400%;
                }
    
                .gradient-3 {
                    height: auto;
                    width: 100%;
                    background: linear-gradient(149deg, rgba(70, 70, 70, 0.75)  0%, rgba(190, 190, 190, 0.75) 42%, rgba(51, 57, 92, 0.75) 72%, #a1a1a1 100%);
                    animation: gradient-3 12s infinite linear;
                    background-size: 400%;
                }  `}
            </style>
        </Box>
    );
};

export default HomePage;
