import { Box, Typography } from '@mui/material';
import { useDevice } from 'hooks/useDevice';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from 'router/routes';

const CustomLogo = () => {
    const navigate = useNavigate();
    const { m } = useDevice();

    return (
        <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }}
            onClick={() => {
                navigate(HOME_ROUTE?.root());
            }}
        >
            <Box
                sx={{
                    boxShadow: '0 0 5px 1px #414141c5',
                    borderRadius: '50%',
                    border: '2.2px solid #383838',
                }}
            >
                <Box
                    sx={{
                        width: m ? 36 : 45,
                        height: m ? 36 : 45,
                        borderRadius: '50%',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <img
                        src={require(`assets/img/main_logo.webp`)}
                        style={{
                            width: m ? 40 : 54,
                            height: m ? 40 : 54,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                        }}
                        alt="logo"
                    />
                </Box>
            </Box>

            <Box
                mt={0.5}
                sx={{
                    position: 'relative',
                    display: 'flex',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#000000',
                        textShadow: '#ffffff 0 0 2.25px',
                        mr: 0.1,
                    }}
                >
                    A
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#000000',
                    }}
                >
                    l
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#000000',
                    }}
                >
                    b
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#000000',
                    }}
                >
                    e
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#000000',
                    }}
                >
                    r
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#000000',
                    }}
                >
                    t
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#000000',
                    }}
                >
                    o
                </Typography>

                <Typography
                    sx={{
                        ml: 1,
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#000000',
                        textShadow: '#ffffff 0 0 2.25px',
                        mr: 0.1,
                    }}
                >
                    B
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        color: '#000000',

                        fontWeight: 500,
                    }}
                >
                    i
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#000000',
                    }}
                >
                    n
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#000000',
                    }}
                >
                    i
                </Typography>
                <Box ml={m ? -0.25 : 0} mt={m ? 0 : 0} sx={{ display: 'flex', transform: 'rotate(5deg)' }}>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: m ? 12 : 16,
                            fontWeight: 600,
                            color: '#3f5370',

                            mr: 0.1,
                        }}
                    >
                        S
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: m ? 12 : 16,
                            color: '#3f5370',

                            fontWeight: 600,
                        }}
                    >
                        h
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: m ? 12 : 16,
                            fontWeight: 600,
                            color: '#3f5370',
                        }}
                    >
                        o
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: m ? 12 : 16,
                            fontWeight: 600,
                            color: '#3f5370',
                        }}
                    >
                        p
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default CustomLogo;
