import { Box, Typography } from '@mui/material';
import { useDevice } from 'hooks/useDevice';
import React from 'react';

const WelcomeLogo = () => {
    const { sx } = useDevice();

    return (
        <Box
            className="WelcomeLogo"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                animation: `fadeIn 2200ms linear forwards`,
                transformOrigin: 'center',
                '@keyframes fadeIn': {
                    '0%': { transform: 'scaleY(0)' },
                    '30%': { transform: 'scaleY(1)' },
                    '75%': { transform: 'scaleY(1)', opacity: 1 },
                    '100%': { opacity: 0 },
                },
            }}
        >
            <Box
                sx={{
                    borderRadius: '50%',
                    overflow: 'hidden',
                    border: '1px solid #fff',
                    width: sx ? 100 : 130,
                    height: sx ? 100 : 130,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img src={require('assets/img/main_logo.webp')} style={{ height: sx ? 100 : 130 }} alt="img" />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    cursor: 'pointer',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 600,
                            textShadow: '#0000006a 0 0 2px',
                            mr: 0.1,
                        }}
                    >
                        A
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 500,
                            color: '#000',
                        }}
                    >
                        l
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 500,
                            color: '#000',
                            textShadow: '#000 0 0 2.25px',
                        }}
                    >
                        b
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 500,
                            color: '#000',
                        }}
                    >
                        e
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 500,
                            color: '#000',
                            textShadow: '#000 0 0 2.25px',
                        }}
                    >
                        r
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 500,
                            color: '#000',
                            textShadow: '#000 0 0 2.25px',
                        }}
                    >
                        t
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 500,
                            color: '#000',
                            textShadow: '#000 0 0 2.25px',
                        }}
                    >
                        o
                    </Typography>
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 600,
                            textShadow: '#0000006a 0 0 2px',
                        }}
                    >
                        B
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 500,
                            color: '#000',
                            textShadow: '#000 0 0 2.25px',
                        }}
                    >
                        i
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 500,
                            color: '#000',
                            textShadow: '#000 0 0 2.25px',
                        }}
                    >
                        n
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Young Serif',
                            fontSize: sx ? 40 : 54,
                            fontWeight: 500,
                            color: '#000',
                            textShadow: '#000 0 0 2.25px',
                        }}
                    >
                        i
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default WelcomeLogo;
